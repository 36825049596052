import React from "react";
import "./Testimonials.css";

const Testimonial = (props) => {
  return (
    <div className="testimonial">
      <div className="quote">{props.quote}</div>
      <div className="company">{props.company}</div>
    </div>
  );
};

export default function Testimonials(props) {
  return (
    <div className="testimonials">
      <Testimonial 
      quote=" 「Aloha Service Specialistsさんはリーズナブルな価格できめ細かいサービス、
      丁寧なご対応をしてくださるのでお客様からの評判もとても良いです。不動産管理会社として信頼のおけるクリーニングサービスを提供していただける　Aloha Service Specialists さんにはとても感謝しております。
      」" 
      company="— Hawaiian Joy"
      />
      <Testimonial 
      quote=" 「日本のおもてなしを理解された、Aloha Service Specialists社のクリーニングは、細部に行き届いた丁寧さがあり、不在期間の長い別荘オーナー様に安心を与える一助となり、また物件購入後のアフターサービスが充実していることは弊社販売活動の強みにもなっています。」" 
      company="— Seven Signatures International"
      />
      <Testimonial 
      quote="「Aloha Service Specialistsの清掃チームはすばらしいです。素早い対応や柔軟性があり、私たちのニーズに丁寧に答えてくれるAloha Service Specialists を高く評価させていただきます。」" 
      company="— One Pacific Realty"
      />
      <Testimonial 
      quote="「退去後のクリーニングを主にお願いしていますが、清掃一般に対する知識が豊富にあり、細部にも配慮していただけるので安心してお任せすることができます。また、こちらの声にも耳を傾けてくださり、柔軟に対応してくださるのがありがたいです。」" 
      company="— Family Hawaii Realty"
      />
        <Testimonial 
      quote=" 「長年不動産業をやっている中で、常に求めているのがクリーニング業者と、修理(含ハイデマン)業者です。多くのクリーニング業者の中でAloha Service Specialists さんはその素早い対応、柔軟さ、お客様の納得がいくまでの細部にわたるお掃除の丁寧さ、などで満足しています。」" 
      company="— Plus Seven Realty"
      />
    </div>
  );
}
