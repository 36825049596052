import React from 'react'
import './Service.css'
import ServiceDescription from './ServiceDescription'
import ServiceGrid from './ServiceGrid'

export default function Service() {
    return (
        <div className="Service" id="section1">
            <div className="serviceMark2">
            清掃サービス 
            </div>
            <ServiceDescription/>
            <br/>
            <br/>
            <br/>
            <div className="descriptionTitle">
                サービス
            </div>
            <br/>
            <ServiceGrid/>
        </div>
    )
}
