import React from "react";
import Contact from "../Contact Section/Contact";
import ContactEn from "../Contact Section/ContactEn";
import InfoLayout from "../InfoLayout";

export default function RestaurantPageEn() {
  return (
    <div>
      <div className="ServicePages">
        <div className="serviceMark">Restaurant Cleaning</div>
        <InfoLayout
          subheader=""
          paragraph="Cleaning is performed on a frequent basis before opening and after closing for the purpose of maintaining the restaurant’s aesthetics and hygiene.  In restaurants, oil stains tend to accumulate and leave stubborn stains that cannot be removed even with a strong detergent. "
        />
        <InfoLayout
          subheader="Cleaning Description"
          paragraph={
            <div>
              - Entrance: Cleaning entrance mat, windows, signs, as well as
              picking up and dumping garbage that collects in parking spaces and smoking spaces 
              <br/>
              <br/>
              - Waiting Space: Cleaning around the cash register, cleaning chairs,
              tables,and decorations, sweeping, wiping, mopping 
              <br/>
              <br/>
              - Floor: Wiping and replenishing tables, chairs and fixtures, sweeping, mopping 
              <br/>
              <br/>
              - Restroom: Removing mold and dirt from the toilet bowl, cleaning the
              washbasin and mirror, replenishment of equipment and disposal of
              garbage, sweeping, mopping 
              <br/>
              <br/>
              - Kitchen: floor brushing with a deck brush, countertop cleaning, dirty and slimy kitchen sink, wiping and cleaning, garbage disposal
            </div>
          }
        />
      </div>
      <ContactEn />
    </div>
  );
}
