import React from "react";
import Contact from "../Contact Section/Contact.js";
import ContactEn from "../Contact Section/ContactEn.js";
import InfoLayout from "../InfoLayout";

export default function VillaPageEn() {
  return (
    <div>
      <div className="ServicePages">
        <div className="serviceMark">Villa Cleaning</div>
        <InfoLayout
          subheader=""
          paragraph="We provide check-in and check-out cleaning, as well as regular cleaning, ventilation, water flow, etc. of important rooms while the owner is absent so that the owner can stay comfortably in a clean room whenever they are at the property. Owners can enjoy a more secure and comfortable stay by preserving the value and condition of the villa through regular cleaning and maintenance."
        />
        <InfoLayout
          subheader="Cleaning Description"
          paragraph={
            <div>
              - Ventilation: Open the windows of all rooms to take in fresh air
              and prevent mold from growing due to humidity.
              <br />
              <br />
              - Water flow: If you do not flow water through drains and faucets for a long
              period of time, the drain trap can run out of water and will give
              off a foul odor. To prevent this, a small amount of water is
              passed regularly.  
              <br />
              <br />
              - Water related items: Bathrooms, kitchens, dishwashers, toilets, washing machines, all of these running
              water regularly to prevent sewage and sewage odors from being trapped.  This also aids in the 
              removal of mold, and in sterilization.
              <br />
              <br />
              - Lightning and remote controls:  Check the bulbs for all lights, and check the batteries of TV
              remote controls and AC remote controls
              <br />
              <br />
              - Termites and pest monitoring: Confirm the presence or absence of pests such as
              termites
              <br />
              <br />
              - Dust and dirt prevention:  Wiping, dust removal, vacuuming, mopping
              <br />
              <br />
              - Plant care: Watering plants and foliage plants
              <br />
              <br />
              - Surveillance for maintenance: Take a photo or video and report if something seems wrong
            </div>
          }
        />
        <InfoLayout
          subheader="
          Check-in and check-out cleaning
          "
          paragraph="
          Cleaning the kitchen, toilet and bathroom, living room, closet, and lanai.  Also, cleaning the entrance, washing sheets, towels and linens, making bed, and cleaning the garbage disposal
          "
        />
      </div>
      <ContactEn/>
    </div>
  );
}
