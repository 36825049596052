import React from 'react'
import './Contact.css'

export default function ContactEn() {
    return (
        <div className="Contact" style={{fontStyle:"serif"}} id="section4">
            <div className="contactDescription">
           Contact us to schedule a free in-person estimate, or make detailed requests.  Once the quote for the cleaning is determined, we can set up schedules and cleaning times that best serve your needs.  
            </div>
            <div className="contactBtn2">
                <a href="mailto:info@alohaservicespecialists.com">
                Contact Us
                </a>
            </div>
            <div className="copyright">©2021 Aloha Service Specialists LLC All Rights Reserved</div>
        </div>
    )
}
