import React from "react";
import "./Testimonials.css";

const Testimonial = (props) => {
  return (
    <div className="testimonial" style={{fontStyle:"serif"}}>
      <div className="quote">{props.quote}</div>
      <div className="company">{props.company}</div>
    </div>
  );
};

export default function TestimonialsEn(props) {
  return (
    <div className="testimonials"  style={{fontStyle:"serif"}}>
       <Testimonial 
      quote='"Aloha Service Specialists offers detailed cleaning at a reasonable price, with carefully handled jobs that receive a good reputation from our customers. We are very grateful to Aloha Service Specialists for providing a reliable cleaning service as a real estate management company."'
      company="— Hawaiian Joy"
      />
      <Testimonial 
      quote='"Understanding the Japanese way of omotenashi, the detailed cleaning of Aloha Service Specialists extend to the smallest refined detail, providing relief for home owners who are present or away from home.  We particularly like the completeness of the after cleaning of sold homes, which we believe strengthens our company&apos;s real estate activities."'
      company="— Seven Signatures International"
      />
       <Testimonial 
      quote='"Toshie and her crew are great! They are responsive, flexible, and thorough while being courteous and accommodating to our needs. We highly recommend Aloha Service Specialists!"'
      company="— One Pacific Realty"
      />
       <Testimonial 
      quote='"Their cleaning standard is very high.  With their experience and knowledge, as well as attention to details, we can rest assured that they will do a great job everytime.  Mahalo always!"'
      company="— Family Hawaii Realty"
      />
        <Testimonial 
      quote='"Having been in the real estate business for many years, I am always looking for a cleaning company and a repair company (including a handyman). Among the many cleaning companies, we are pleased with Aloha Service Specialists’ quick response, flexibility, and meticulous cleaning that provides satisfaction to customers.
      "'
      company="— Plus Seven Realty"
      />
    </div>
  );
}
