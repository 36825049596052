import React from "react";

function Content(props) {
  return (
    <div className="descriptionContent">
      <div className="cleaningTitles">
        <b>{props.title}</b>
      </div>
      <div className="cleaningdescriptions"></div>
      {props.description}
      <br />
      <br />
    </div>
  );
}

function Title(props) {
  return <div className="descriptionHeader">{props.title}</div>;
}

export default function ServiceDescriptionEn(props) {
  return (
    <div className="ServiceDescriptions">
      <div className="descriptionTitle">Cleaning Description</div>
      <div>
        <Title title="Kitchen" />
        <Content
          title="Dirt around wet environment "
          description="We use an acidic detergent or a neutral detergent to scrub off water stains and dirt on the sink and faucet with a sponge, removing slime, dirt, and mold from the drain."
        />
        <Content
          title="Dirt around stoves"
          description="We use alkaline detergent to remove oil stains around the stove and walls. Stubborn oil stains on the stove are sprayed with detergent, left for a while and then scraped off."
        />
        <Content
          title="In-sink Garbage Disposal"
          description="Various types of dirt like kitchen waste and oil reside inside the sink, causing slime, foul odor, mold, etc.  Since the inside of the disposal is composed of various parts, if you wash with strong detergent or boiling water, it may cause damage or malfunction, so we make sure to clean the disposal safely by first adding a few pieces of ice and a few drops of neutral detergent. We scrape off any remaining dirt with a sponge for maximum effectiveness."
        />
        <br />
        <br />
        <Title title="Shower" />
        <Content
          title="Water Stains"
          description="An acidic detergent is used to scrape and clean."
        />
         <Content
          title="Hot Water Stains"
          description="An alkaline solution is used alongside acidic detergents to completely eliminate the stain."
        />
        <Content
          title="Black Mold"
          description="Mold typically occurs in a humid environment and resides in between tiles. We first dry the bathroom, spray a mold remover, wrap it in plastic wrap or kitchen paper, then wash off the detergent in the shower. After removing the mold, we thoroughly dry the area and gently scrape off the parts that cannot be removed with a brush."
        />
        <Content
          title="Pink Stains"
          description="Found on the floor, walls, the bottom of shampoo bottles, and drains. These stains can form black mold if untreated. We first remove soap scum and dirt with a hot water shower, then disinfect and sterilize with a disinfectant detergent, and dry the area."
        />
        <Content
          title="Mirror and Glass"
          description="Cloudy spots are caused by crystallized minerals in tap water and soap residues. We spray the spots with an acidic detergent, attach a wrap to prevent it from drying, leave it for a while, and then gently scrape off the spots with a special cleanser."
        />
        <br />
        <br />
        <Title title="Bathroom" />
        <Content
          title="Toilet"
          description="Yellow stains on toilets are caused by crystallized urinary stones that contain calcium and proteins. Darkening of stains and foul odors are generated as a result of microorganism growing from the stains. The urinary stones that cause the problem are alkaline, so after using an acidic solvent to lift the stains, we make sure to thoroughly scrub the inside of the toilet bowl, the back of the toilet seat, the edge of the toilet bowl, the floor, and the wall, where stains are most present.  We also clean the molds inside of the toilet tank, which prevent the toilet from growing new stains."
        />
        <br />
        <br />
        <Title title="Floors" />
        <Content
          title="Solid single layer flooring"
          description="Solid single layer flooring is finished only with natural wood without using additional materials or thin wood. Depending on the humidity of the room, the solid wood absorbs and releases moisture, so we try to keep the humidity in the room constant.  We wipe the floor with a tightly wrung towel to leave no dirt.  Natural oils will come out from the solid wood itself, so we rub it with a dry towel to give the floor a natural finish."
        />
        <Content
          title="Plywood composite flooring"
          description="Conventional flooring material finished with decorative wood based on plywood veneer etc."
        />
        <Content
          title="Black Spots"
          description="Black spots are often found on floors in kitchens and around the entrance, mainly due to oil stains on the soles of the feet. We remove stains from the floor with alkaline detergent and neutral detergent so that the wax is not affected."
        />
        <Content
          title="Mold"
          description="Mold often occurs on floors in damp closets or under beds. Before vacuuming, we use a disinfectant spray to gently wipe off the wax so that it does not sprinkle mold spores."
        />
        <Content
          title="Spots from rubber material on chairs, tables, furniture, crayons, etc."
          description="Since rubber has the property of dissolving in oil, we soak it in hand cream or cleansing oil, remove it, and wipe it off so that it does not remain sticky."
        />
      </div>
    </div>
  );
}
