import './Service.css'
import ServiceGridEn from './ServiceGridEn'
import ServiceDescriptionEn from './ServiceDescriptionEn'


export default function ServiceEn() {
    return (
        <div className="Service" id="section1" style={{fontStyle:"serif"}}>
            <div className="serviceMark2">
                Cleaning Services 
            </div>
            <ServiceDescriptionEn/>
            <br/>
            <br/>
            <br/>
            <div className="descriptionTitle">
                Our Cleaning Services
            </div>
            <ServiceGridEn/>
        </div>
    )
}
