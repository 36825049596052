import React from 'react'
import Contact from '../Contact Section/Contact'
import InfoLayout from '../InfoLayout'

export default function MoveoutPage() {
    return (
        <div>
        <div className="ServicePages">
            <div className="serviceMark">
            退去後のハウスクリーニング
            </div>
            <InfoLayout
            subheader=""
            paragraph="退去時の清掃です。（ディープクリーニング)
            お部屋の汚れ具合によっては敷金から大きなマイナス対象となってしまうこともあります。可能な限り引越ししてきた時の状態、原状回復につとめます。
            不動産屋さんや、オーナー様より清掃のチェックリストの提示がありましたらそれにそって清掃させて頂きます。
            "
            />
             <InfoLayout
            subheader=""
            paragraph={
                <div>
                    ―キッチンのシンク、蛇口の汚れ、水垢やくすみ
                    <br/>
                    <br/>
                    ―ディスポーザーのヌメリや汚れ
                    <br/>
                    <br/>
                    ―オーブン、ストーブ、換気扇、壁や床の油汚れ
                    <br/>
                    <br/>
                    ―冷蔵庫の中と外の汚れ、ゴムパッキンのカビや汚れ
                    <br/>
                    <br/>
                    ―キャビネットの中と外の汚れ
                    <br/>
                    <br/>
                    ―トイレの黄ばみ黒ずみ
                    <br/>
                    <br/>
                    ―かがみ、ガラスのくすみ、水垢
                    <br/>
                    <br/>
                    ―バスルーム 水垢、湯垢、カビ、蛇口のくすみ
                    <br/>
                    <br/>
                    ―お部屋全体の壁とドアの汚れ
                    <br/>
                    <br/>
                    ―窓ガラスとサッシとラナイ
                </div>
            }
            /> 
        </div>
        <Contact/>
        </div>
    )
}
