import React from 'react'

function Content(props) {
    return (
        <div className="descriptionContent">
        <div className="cleaningTitles"><b>{props.title}</b></div>
        <div className="cleaningdescriptions"></div>
        {props.description}
        <br/>
        <br/>
    </div>
    )
}

function Title(props) {
    return(
        <div className="descriptionHeader">
            {props.title}
            </div>
    )
}

export default function ServiceDescription(props) {
    return (
        <div className="ServiceDescriptions">
            <div className="descriptionTitle">
                清掃内容
            </div>
            <div>
           <Title title="キッチン"/>
           <Content 
           title="水回りの汚れ"
           description="シンクや蛇口の水垢やよごれは酸性洗剤、もしくは中性洗剤をつけスポンジでこすり洗い落とします。排水口のヌメリや汚れ、カビなどもしっかり落とします。"
           />
           <Content
           title="火周りの汚れ"
           description="コンロ周りや壁などの油汚れはアルカリ洗剤を使います。コンロの頑固な油汚れは洗剤を吹き付けしばらく放置したあとこすり落とします。"
           />
            <Content
           title="ディスポーザー"
           description="内部には色々な種類の生ゴミや調理で発生した油分などさまざまな汚れが付着しているため、ヌメリや悪臭、カビなどが発生します。ディスポーザーの内部は様々なパーツで構成されているため強力な洗剤や熱湯をし流してしまうと破損や不具合の原因になるため、まず氷を数個入れ、食器用中性洗剤を数滴たらし回し、ぬめりを取ります。残った汚れはスポンジでこすって落とします。"
           />
           <br/>
           <br/>
           <Title title="バスルーム"/>
           <Content 
           title="水垢"
           description="ざらざら、カリカリとした白い固まり。水道水に含まれるミネラル分から水分けが蒸発して残ったもの酸性洗剤を使い取り除きながらこすり落とします。"
           />
             <Content 
           title="湯垢"
           description="ヌルヌルとした汚れ。水垢に加えて石鹸カス、皮脂汚れがミックスしたものです。酸性の洗剤では落ちないためアルカリ性も使い完全に落とす。"
           />
           <Content
           title="黒カビ"
           description="高温多湿の汚れたところに発生し、ゴムパッキンやタイルの目地の奥ま入り込んでいきます。まずは浴室の水気をしっかり拭き取り、カビ取り剤を吹き付けラップやキッチンペーパーなどでラップししばらくおいた後シャワーで洗剤を洗い流す。落ちきれない箇所はブラシでこすり取るカビ取りが終わったら しっかり水気を取ります。"
           />
            <Content
           title="ピンク色の汚れ"
           description="ヌルヌルとしたピンク色の汚れは床、壁、シャンプーのボトルの底や、排水口など あちこちに発生し、放っておくと黒カビが発生します。取り除くためにまず熱湯シャワーで石鹸カスや汚れをしっかり落とし、そのあと除菌洗剤を使い消毒、殺菌し水気をしっかり拭き取ります。"
           />
            <Content
           title="鏡やガラス"
           description="ウロコ状の白いくもりは、水道水のミネラルが結晶化したものや、石鹸カスなどの水垢が原因です。酸性の洗剤などを吹き付け乾燥しないようラップを貼り付け しばらく放置したあと、専用のクレンザーでこすり落とします。"
           />
           <br/>
           <br/>
           <Title title="トイレ"/>
           <Content 
           title="便器"
           description="黄ばみの原因は尿に含まれるカルシウムやタンパク質が結晶化した尿石です。その黄ばみにカビが生えたり微生物が原因で黒ずみになり悪臭が発生します。問題の原因となっている尿石はアルカリ性なので、酸性の溶剤を使い汚れを浮かせた後で黄ばみができやすい便器の中、便座の裏、便器のフチ、床、壁などをしっかりこすり洗いします。同時に、タンク内部でカビが発生していると便器も汚れやすくなるためタンク内を確認し洗浄します。"
           />
            <br/>
           <br/>
           <Title title="フローリング"/>
           <Content 
           title="無垢 単層フローリング"
           description="継ぎ足し素材を使用したり薄い木材を重ねたりせず天然木材だけで仕上げた床材で調湿作用があり、お部屋の湿度によって無垢材が水分を吸収、放出すれ作用があるため室内の湿度を一定に保とうとします。硬く絞ったタオルで汚れを残さないよう拭き、無垢の木自体から油脂分が表面に出てくるので、 乾いたタオルでこするように吹きツヤを出します。

           "
           />
            <Content 
           title="合板 複合フローリング"
           description="合板ベニアなどをベースに化粧用の木材をはって仕上げた床材"
           />
            <Content 
           title="黒ずみ"
           description="主に足裏の皮脂汚れや油汚れが原因で、キッチンや玄関周りによくあります。床用アルカリ洗剤や中性洗剤をワックスに影響がでないよう落とします。"
           />
            <Content 
           title="カビ"
           description="湿気の多いクローゼットやベッドの下などに発生します。カビの胞子をまきちらさないよう掃除機をかける前に除菌スプレーを使いワックスに影響がでないよう そっと拭き取ります。"
           />
           <Content 
           title="イス、テーブル、家具についているゴムや、クレヨンなどの汚れ"
           description="ゴムは油に溶ける性質があるのでハンドクリームやクレンジングオイルなどで馴染ませた後落とし、べたつきが残らないようしっかり拭き取ります。
           この３つの汚れを落とした後、乾いたモップやワイパーで埃を取り除き、掃除機をかけます。それから固く絞ったタオルで床を手早く拭きあげ、乾いたタオルで水気をしっかり乾拭きします。
           "
           />
            </div>
        </div>
    )
}
