import React from 'react'
import Contact from '../Contact Section/Contact'
import ContactEn from '../Contact Section/ContactEn'
import InfoLayout from '../InfoLayout'

export default function MoveoutPageEn() {
    return (
        <div>
        <div className="ServicePages">
            <div className="serviceMark">
             Move Out Cleaning 
            </div>
            <InfoLayout
            subheader=""
            paragraph="Depending on how dirty the environment is, the security deposit may be a big negative target.
            We will try our best to restore the original condition of the room with deep cleaning. 
            "
            />
             <InfoLayout
          subheader="Cleaning Description"
          paragraph={
            <div>
              - Kitchen: Sink, dirt on faucet, water stains
              <br />
              <br/>
             - Oven, stove, oil stains and dirt on walls and floors 
              <br />
              <br/>
              - Mold and dirt on refrigerator
              <br />
              <br/>
              - Cabinets
              <br />
              <br/>
              - Mirrors, cloudy marks on glasses, water stains
              <br />
              <br/>
             - Water stains, molds, and dirt in bathrooms 
             <br />
              <br/>
              - Dirt on walls and doors
              <br />
              <br/>
              - Cleaning windows and lanais
            </div>
          }
        />
        </div>
        <ContactEn/>
        </div>
    )
}
