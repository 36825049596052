import React from "react";
import Contact from "../Contact Section/Contact";
import ContactEn from "../Contact Section/ContactEn";
import InfoLayout from "../InfoLayout";

export default function OfficePageEn() {
  return (
    <div>
      <div className="ServicePages">
        <div className="serviceMark">Office Cleaning</div>
        <InfoLayout
          subheader=""
          paragraph="An office cleaning for maintaining aesthetics and hygiene to create an environment where employees can work comfortably and leave a positive impression on visitors.  "
        />
        <InfoLayout
          subheader="Cleaning Description"
          paragraph={
            <div>
              - Office: Vacuuming, wiping, mopping, garbage collection
              <br />
              <br/>
              - Meeting rooms, reception rooms: Vacuuming, wiping, mopping,
              garbage collection
              <br />
              <br/>
              - Restrooms: Toilet bowl cleaning, mirror polishing, wash basin
              cleaning, wiping and cleaning counters, mopping, garbage collection
              <br />
              <br/>
              - Dining room, hot water supply room: Cleaning the sink,
              replenishing and checking equipment, wiping, sweeping, mopping,
              garbage collection
              <br />
              <br/>
              - Corridors, stairs: Vacuuming, mopping, wiping
              <br />
              <br/>
              - Entrance: Vacuuming, wiping, mopping, glass wiping,
              garbage collection
            </div>
          }
        />
      </div>
      <ContactEn/>
    </div>
  );
}
